<template>
  <div>
    <KTCard title="Bank Receipt" v-if="feature.bankreceipt">
      <template v-slot:toolbar>
        <b-button
          @click="openModal('modalUploadBankReceipt')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Upload Bank Receipt</b-button
        >
      </template>

      <template v-slot:body>
        <b-tabs content-class="mt-3">
          <div v-for="(tab, index) in bankReceiptTabs" :key="index">
            <b-tab :title="tab.key">
              <b-row v-if="!!tableSettings">
                <b-col cols="6">
                  <b-form-group class="m-2" label-size="sm">
                    <template v-if="tableSettings.refresh">
                      <b-button size="sm" class="mr-2">
                        <i class="fa fa-sync"></i> Refresh
                      </b-button>
                    </template>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group
                    class="m-2"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                  >
                    <template v-if="tableSettings.filter">
                      <b-input-group size="sm">
                        <b-form-input
                          v-model="tab.filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button
                            :disabled="!tab.filter"
                            @click="tab.filter = ''"
                            >Clear</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-table
                :per-page="tableSettings.perPage"
                :current-page="tableSettings.currentPage"
                :filter="tab.filter"
                striped
                show-empty
                bordered
                hover
                :items="getItems(tab.items)"
                :fields="bankReceiptFields"
              >
                <template v-slot:cell(actions)="row">
                  <b-button-group size="sm">
                    <b-button
                      @click="
                        onReceipt(row.item.bank_receipt_id, row.item.filename)
                      "
                      variant="primary"
                    >
                      Receipt
                    </b-button>
                  </b-button-group>
                </template>
              </b-table>

              <b-pagination
                v-if="tableSettings.row > tableSettings.perPage"
                class="pl-0"
                v-model="tab.currentPage"
                :total-rows="tableSettings.rows"
                :per-page="tableSettings.perPage"
              ></b-pagination>
            </b-tab>
          </div>
        </b-tabs>
      </template>
    </KTCard>
    <KTCard title="Online Payment" v-if="feature.online">
      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="3">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="billplzTable.filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!billplzTable.filter"
                      @click="billplzTable.filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="billplzTable.filter"
          striped
          bordered
          show-empty
          hover
          :items="OnlineBillplz"
          :fields="fields"
        >
          <template v-slot:cell(billplz_bill_status_id)="row">
            <span
              :class="{
                'text-warning': row.item.billplz_bill_status_id === 2,
                'text-success': row.item.billplz_bill_status_id === 1,
                'text-danger': row.item.billplz_bill_status_id === 3,
              }"
              >{{
                InvoiceStatusConstant(row.item.billplz_bill_status_id)
              }}</span
            >
          </template>
          <template v-slot:cell(amount)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.amount"
            ></vue-numeric>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button @click="onPay(row.item)" variant="primary" size="sm">
                Pay
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="billplzTable.currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <modal-upload-bank-receipt></modal-upload-bank-receipt>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import { validationMixin } from "vuelidate";
import {
  // CREATE_BILL,
  // GET_BILL,
  CA_DOWNLOAD_BANK_RECEIPT,
  GET_INVOICE_LIST,
  GET_BANK_RECEIPT_LIST,
  GET_APPROVED_BANK_RECEIPT_LIST,
  GET_REJECTED_BANK_RECEIPT_LIST,
  RESET_TEMP_BILL_LIST,
  // SET_TEMP_BILL_LIST,
  UPLOAD_BANK_RECEIPT,
} from "@/core/services/store/modules/clientadmin/billing.module";
import { SYSTEM_CONSTANTS } from "@/core/services/store/modules/ui.module";
import ModalUploadBankReceipt from "@/view/pages/clientadmin/billing/modals/ModalUploadBankReceipt";
import VueNumeric from "vue-numeric";

export default {
  name: "make-payment-page",
  mixins: [validationMixin],
  components: { ModalUploadBankReceipt, VueNumeric },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Invoice" },
      { title: "Payment" },
    ]);
    await this.$store.dispatch(SYSTEM_CONSTANTS);

    // get payment type by filtering invoice list
    this.invoice_id = this.$route.params.invoiceId;
    await this.$store
      .dispatch(GET_INVOICE_LIST, { length: 100, start: 0, q: "" })
      .then((res) => {
        if (res.response_code == 2100) {
          let invoice = this.InvoiceList.find(
            (invoice) => invoice.invoice_id == this.invoice_id
          );
          this.paymentType = invoice.payment_channel_id;
          console.log("Payment Type", this.paymentType);
        }
      });

    // get bill/bank receipt based on payment Type
    if (this.paymentType == 2) {
      let bankReceiptPayload = {
        ...this.defaultPayload,
        invoice_id: this.invoice_id,
      };
      await this.$store.dispatch(GET_BANK_RECEIPT_LIST, bankReceiptPayload);
      await this.$store.dispatch(
        GET_APPROVED_BANK_RECEIPT_LIST,
        bankReceiptPayload
      );
      await this.$store.dispatch(
        GET_REJECTED_BANK_RECEIPT_LIST,
        bankReceiptPayload
      );

      this.feature.bankreceipt = true;
      this.feature.online = false;
    } else if (this.paymentType == 1) {
      this.feature.bankreceipt = false;
      this.feature.online = true;
    }
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.clientadmin_billing.bankReceiptList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    BankReceiptList() {
      const list = this.$store.state.clientadmin_billing.bankReceiptList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    ApprovedBankReceiptList() {
      const list = this.$store.state.clientadmin_billing
        .approvedBankReceiptList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    RejectedBankReceiptList() {
      const list = this.$store.state.clientadmin_billing
        .rejectedBankReceiptList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    InvoiceList() {
      const list = this.$store.state.clientadmin_billing.invoiceList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    CurrentInvoice() {
      let invoice = this.InvoiceList;
      invoice = invoice.find(
        (invoice) => invoice.invoice_id == this.invoice_id
      );
      return invoice;
    },
    OnlineBillplz() {
      let invoice = this.CurrentInvoice;
      if (invoice && Array.isArray(invoice.bills) && invoice.bills.length) {
        return invoice.bills;
      } else {
        return [];
      }
    },
    BillList() {
      const list = this.$store.state.clientadmin_billing.bill;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      paymentType: null,
      form: {},
      billplzTable: {
        filter: null,
        currentPage: 1,
      },
      bankReceiptTabs: [
        {
          key: "Pending",
          filter: null,
          currentPage: 1,
          items: "BankReceiptList",
        },
        {
          key: "Approved",
          filter: null,
          currentPage: 1,
          items: "ApprovedBankReceiptList",
        },
        {
          key: "Rejected",
          filter: null,
          currentPage: 1,
          items: "RejectedBankReceiptList",
        },
      ],
      bankReceiptFields: [
        { key: "bank_receipt_id", label: "Receipt ID", sortable: true },
        { key: "bank_name", label: "Bank Name", sortable: true },
        { key: "bank_account", label: "Bank Account", sortable: true },
        { key: "approve_amount", label: "Amount", sortable: true },
        {
          key: "bank_receipt_datetime",
          label: "Receipt Date",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Uploaded On",
          class: "text-right",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          headerTitle: "",
          thStyle: "width: 50px; min-width: 50px",
        },
      ],
      fields: [
        { key: "bill_id" },
        { key: "billplz_unique_ref" },
        { key: "billplz_bill_status_id", label: "Status" },
        { key: "amount", label: "Total Amount" },
        { key: "actions", class: "text-right" },
      ],
      feature: {
        bankreceipt: false,
        online: false,
      },
    };
  },
  destroyed() {
    this.$store.commit(RESET_TEMP_BILL_LIST);
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submitForm();
    },
    onPay(invoice) {
      console.log(invoice);
      window.open(invoice.payment_url, "_blank");
    },
    onReceipt(receiptId, filename) {
      const payload = {
        bank_receipt_id: receiptId,
        filename: filename,
        company_id: this.InvoiceList[0].company_id,
      };
      this.$store.dispatch(CA_DOWNLOAD_BANK_RECEIPT, payload);
    },
    getItems(v) {
      return this[v];
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");
        let payload = this.form;
        this.$store.dispatch(UPLOAD_BANK_RECEIPT, payload).then((resp) => {
          if (resp.response_code === 2100) {
            console.log("UPLOAD_BANK_RECEIPT SUCCESS");
          } else {
            console.log("UPLOAD_BANK_RECEIPT FAILED");
          }
        });
      }
    },
  },
};
</script>
